import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CattleModal = ({ show, onHide, cattleData, onSave }) => {
  const [editedCattle, setEditedCattle] = useState(cattleData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedCattle({ ...editedCattle, [name]: value });
  };

  const handleSave = () => {
    onSave(editedCattle); // Save the updated cattle data
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Cattle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTagNumber">
            <Form.Label>Tag Number</Form.Label>
            <Form.Control
              type="text"
              name="tag_number"
              value={editedCattle.tag_number}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formTagColor">
            <Form.Label>Tag Color</Form.Label>
            <Form.Control
              type="text"
              name="tag_color"
              value={editedCattle.tag_color}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formBirthYear">
            <Form.Label>Birth Year</Form.Label>
            <Form.Control
              type="text"
              name="birth_year"
              value={editedCattle.birth_year}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formBreed">
            <Form.Label>Breed</Form.Label>
            <Form.Control
              type="text"
              name="breed"
              value={editedCattle.breed}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formSex">
            <Form.Label>Sex</Form.Label>
            <Form.Control
              type="text"
              name="sex"
              value={editedCattle.sex}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formColor">
            <Form.Label>Color</Form.Label>
            <Form.Control
              type="text"
              name="color"
              value={editedCattle.color}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formNotes">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="notes"
              value={editedCattle.notes}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CattleModal;
