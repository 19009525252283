import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON, LayersControl, FeatureGroup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import './MapView.css';
import L from 'leaflet';

const MapView = () => {
  const [geoJSONData1, setGeoJSONData1] = useState(null);
  const [geoJSONData2, setGeoJSONData2] = useState(null);

  // Fetch GeoJSON data for each layer
  useEffect(() => {
    fetch('/paddocks.geojson')
      .then((response) => response.json())
      .then((data) => setGeoJSONData1(data))
      .catch((error) => console.error("Error loading GeoJSON 1:", error));

    fetch('/waterBodies.geojson')
      .then((response) => response.json())
      .then((data) => setGeoJSONData2(data))
      .catch((error) => console.error("Error loading GeoJSON 2:", error));
  }, []);

  // Color function for paddocks based on property type
  const getColor = (property) => {
    switch (property) {
      case 'Serenity':
        return '#ff7f00';
      case 'Bendawalla':
        return '#377eb8';
      case 'Smeulders':
        return '#4daf4a';
      default:
        return '#999999';
    }
  };

  // Style for paddocks
  const paddockStyle = (feature) => ({
    fillColor: getColor(feature.properties.Property),
    weight: 1,
    opacity: 1,
    color: '#3388ff',
    fillOpacity: 0.5,
  });

  // Custom icon for water bodies
  const waterBodyIcon = L.icon({
    iconUrl: '/water.svg',  // Replace with the path to your custom icon
    iconSize: [30, 30],  // Adjust icon size as needed
    iconAnchor: [15, 15], // Center the icon on its coordinates
  });

  // Function to display all properties in the popup
  const displayProperties = (feature) => {
    return Object.entries(feature.properties)
      .map(([key, value]) => `<p><strong>${key}:</strong> ${value}</p>`)
      .join('');
  };

  return (
    <MapContainer style={{ height: "500px", width: "100%" }} zoom={13.5} center={[-31.509, 151.221]}>
      <LayersControl position="topright">
        {/* Base Map Layers */}
        <LayersControl.BaseLayer checked name="OpenStreetMap">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; OpenStreetMap contributors'
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="Satellite">
          <TileLayer
            url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
            attribution='&copy; OpenTopoMap contributors'
          />
        </LayersControl.BaseLayer>

        {/* Overlay Layers */}
        <LayersControl.Overlay checked name="Paddock Layer">
          <FeatureGroup>
            {geoJSONData1 && (
              <GeoJSON
                data={geoJSONData1}
                style={paddockStyle}
                onEachFeature={(feature, layer) => {
                  layer.bindPopup(displayProperties(feature));
                  layer.on({
                    mouseover: (e) => {
                      layer.bindTooltip(
                        `<strong>Name:</strong> ${feature.properties.Name}<br /><strong>Area:</strong> ${feature.properties.area} sqm`
                      ).openTooltip(e.latlng);
                    },
                    mouseout: () => {
                      layer.closeTooltip();
                    }
                  });
                }}
              />
            )}
          </FeatureGroup>
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Water Body Layer">
          <FeatureGroup>
            {geoJSONData2 && (
              <GeoJSON
                data={geoJSONData2}
                pointToLayer={(feature, latlng) => L.marker(latlng, { icon: waterBodyIcon })}
                onEachFeature={(feature, layer) => {
                  layer.bindPopup(displayProperties(feature));
                  layer.on({
                    mouseover: (e) => {
                      layer.bindTooltip(
                        `<strong>Name:</strong> ${feature.properties.Name}<br /><strong>ID:</strong> ${feature.properties.id}`
                      ).openTooltip(e.latlng);
                    },
                    mouseout: () => {
                      layer.closeTooltip();
                    }
                  });
                }}
              />
            )}
          </FeatureGroup>
        </LayersControl.Overlay>
      </LayersControl>
    </MapContainer>
  );
};

export default MapView;
