import React from 'react'
import MapView from '../components/MapView';

const HomePage = () => {
  return (
    <>
        <h1>Farm Dashboard</h1>
        <>{< MapView/>}</>

    </>
  )
}

export default HomePage
