import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import {supabase} from '../services/supabaseClient';
import CattleModal from '../components/CattleModal';

const CattleManagement = () => {
  const [cattle, setCattle] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCattle, setSelectedCattle] = useState(null);

  // Fetch cattle data on load
  useEffect(() => {
    fetchCattleData();
  }, []);

  const fetchCattleData = async () => {
    const { data, error } = await supabase.from('cattle').select('*');
    if (error) {
      console.error("Error fetching data:", error);
    } else {
      setCattle(data);
    }
  };

  const handleEdit = (cattleItem) => {
    setSelectedCattle(cattleItem); // Set data for the modal
    setShowModal(true); // Open the modal
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedCattle(null);
  };

  const handleSave = async (updatedCattle) => {
    const { error } = await supabase
      .from('cattle')
      .update(updatedCattle)
      .eq('id', updatedCattle.id);

    if (error) {
      console.error("Error updating cattle:", error);
    } else {
      fetchCattleData(); // Refresh the data
      setShowModal(false);
    }
  };

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Tag Number</th>
            <th>Tag Color</th>
            <th>Birth Year</th>
            <th>Breed</th>
            <th>Sex</th>
            <th>Color</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {cattle.map((cattleItem) => (
            <tr key={cattleItem.id}>
              <td>{cattleItem.tag_number}</td>
              <td>{cattleItem.tag_color}</td>
              <td>{cattleItem.birth_year}</td>
              <td>{cattleItem.breed}</td>
              <td>{cattleItem.sex}</td>
              <td>{cattleItem.color}</td>
              <td>
                <Button variant="primary" onClick={() => handleEdit(cattleItem)}>
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Render the modal only when showModal is true */}
      {showModal && selectedCattle && (
        <CattleModal
          show={showModal}
          onHide={handleModalClose}
          cattleData={selectedCattle}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default CattleManagement;
