import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MenuBar from './components/MenuBar';
import CattlePage from './pages/CattleManagement';
import HomePage from './pages/HomePage';

function App() {
  return (
    <Router>
      <MenuBar />
      <Routes>
        <Route path="/" element={< HomePage/>} />
        <Route path="/cattlemanagement" element={<CattlePage />} />
      </Routes>
    </Router>
  );
}

export default App;
